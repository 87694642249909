import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { getCookie } from '@utils/cookies';
import { getTokenUserAndTenant } from '@utils/token';
import { useSelector } from 'react-redux';
import { UserByIdRequest } from '@cv/portal-idm-lib/user/user-Info/models';
import { formatUserData } from '@api/formatters';
import { getUserInfoByID } from '@cv/portal-idm-lib';
import { useConfig } from '@components/ConfigProvider';
import { UpdatedAccountData } from '@api/types';

enum TokenTypes {
  ID_TOKEN = 'idToken',
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
}

const useAccount = (): UseQueryResult<UpdatedAccountData> => {
  const accessToken = getCookie(TokenTypes.ACCESS_TOKEN);

  const locale = useSelector(({ settingsReducer }) => settingsReducer?.locale);
  const config = useConfig();
  const environment = config.getEnvironmentEnum();

  return useQuery({
    enabled: !!accessToken,
    queryKey: ['accountDetails'],
    queryFn: async () => {
      let userId = '';

      const parsedValues = getTokenUserAndTenant(accessToken);
      userId = parsedValues.userId;

      const payload: UserByIdRequest = {
        environment,
        pathParams: {
          _id: userId,
        },
        headers: {
          Authorization: accessToken,
          'Accept-Language': locale,
        },
      };

      const response = await getUserInfoByID(payload);

      return response.data;
    },
    select: (_data) => formatUserData(_data),
    placeholderData: {
      title: '',
      name: '',
      tenantId: '',
      email: '',
      preferredTimeZone: '',
      mailingCountry: '',
      isPinConfigured: true,
      preferredLanguage: '',
      primaryPhone: {
        number: '',
        type: 'Home',
        typeShortcut: '( c )',
      },
      secondaryPhone: {
        number: '',
        type: 'Home',
        typeShortcut: '( c )',
      },
      homeAddress: {
        street: '',
        unit: '',
        city: '',
        state: '',
        zip: '',
      },
      mailingAddress: {
        street: '',
        unit: '',
        city: '',
        state: '',
        zip: '',
      },
      homeAddressSameAsMailingAddress: true,
      userName: {
        givenName: '',
        fathersName: '',
        mothersName: '',
      },
      notificationPreferences: {
        email: '',
        phone: '',
        services: [],
      },
      vehicles: [],
      roles: [],
      vehicleIds: [],
      _id: '',
      billingId: '',
    },
  });
};

export default useAccount;
