import React from 'react';
import { EligiblePackageInfo } from '../../Types/PackageInfo';
import { ContentfulAssets } from '../..';
import PackageDetailsContent from '@manageSubscription/Discount/PackageDetails/PackageDetailsContent';
import LinkToOkModal from '@app/components-lib/components/Modal/LinkToOkModal';
import { useTheme } from '@mui/material';

export interface PackageDetailProps {
  promoPackage?: EligiblePackageInfo;
  analyticsEventName?: string;
  closeButtonLabel: string;
  packageDetailsLabel: string;
  detailsLabel?: string;
  assets: ContentfulAssets;
}

const PackageDetails = ({
  promoPackage,
  analyticsEventName,
  closeButtonLabel,
  detailsLabel,
  packageDetailsLabel,
  assets,
}: PackageDetailProps) => {
  const theme = useTheme();

  const contrastText = theme.palette.getContrastText(theme.palette.primary.main);

  return (
    <LinkToOkModal
      assets={assets}
      message={<PackageDetailsContent promoPackage={promoPackage} detailsLabel={detailsLabel} />}
      buttonText={closeButtonLabel}
      className="package-details-link link-open"
      analyticsEventName={analyticsEventName}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        color: contrastText,
        fontSize: 16,
        '&:hover': {
          color: contrastText,
        },
      }}
    >
      {packageDetailsLabel}
    </LinkToOkModal>
  );
};

export default PackageDetails;
