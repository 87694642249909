import {
  OrderRequest,
  PreviewOrderResponse,
  SearchEligiblePackagesRequest,
  SubscribedPackage,
  SubscribePackagesRequest,
} from '@cv/portal-cps-lib/subscription/subscription-management/models';
import {
  addVehicleData,
  CONFIG_KEYS,
  containsEligiblePackage,
  formatToCpsTZ,
  packagesToAdd,
  packagesToCancel,
  packagesToRemove,
  SubscriptionProps,
} from '@manageSubscription';
import {
  DiscountInfo,
  EligiblePackageInfo,
  OrderSummaryInfo,
  PackageSubscription,
  PackageToRemoveBuilderType,
  PreviewOrderRequestInfo,
  SubscribedPackageInfo,
} from '@manageSubscription/Types';
import { getClientIp, options } from '../commonService';
import { createOrder, getSubscribedPackages, previewOrder, searchEligiblePackages } from '@cv/portal-cps-lib';
import {
  buildOrderSummary,
  buildOrderTransactionInfo,
  buildPackagesWithDiscount,
  buildSubscribedPackages,
  mapSubscriptionsWithSummaryTotals,
} from '@manageSubscription/builders';
import {
  CancellationPolicy,
  CancelReason,
  PackageType,
  SalesChannel,
} from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { HttpStatus } from '@cv/portal-common-lib/ajax/constants';
import { createAgreementForUser } from '@lib-services/cps/agreementService';
import { PaymentInfo } from '@lib-payment/Types';
import { getDefaultPayment } from '@lib-services/cps/paymentService';
import { TenantIds } from '@manageSubscription/utils/productFlowMap';
import { FlowLocation } from '@lib-appRouter/flowMiddleware';
import { isMarketingFlow } from '@lib-appRouter/flowUtils';

export const getPackagesSubscribed = (props: SubscriptionProps) => {
  const {
    includeInactivePackages,
    userDetails: { userId },
    vehicleDetails,
  } = props;

  const request: SubscribePackagesRequest = {
    ...options(props),
    data: addVehicleData(vehicleDetails, { userId, includeInactive: includeInactivePackages }),
  };
  return getSubscribedPackages(request)
    .then((res) => res.data?.subscribedPackages)
    .catch((err) =>
      console.error(`An error occurred while fetching subscribed packages. ErrorMessage: ${err.data?.message ?? err}`),
    );
};

export const getEligiblePackages = (props: SubscriptionProps) => {
  const {
    userDetails: { userId },
    vehicleDetails,
    salesChannel,
    tenantId,
    location,
  } = props;

  const request: SearchEligiblePackagesRequest = {
    ...options(props),
    data: addVehicleData(vehicleDetails, {
      userId,
      salesChannel: getSalesChannel(salesChannel, tenantId, location),
      retention: true,
    }),
  };
  return searchEligiblePackages(request)
    .then((res) => res.data)
    .catch((err) =>
      console.error(`An error occurred while fetching eligible packages. ErrorMessage: ${err.data?.message ?? err}`),
    );
};

export const getPackagesWithTax = async (
  ePackages: EligiblePackageInfo[],
  props: SubscriptionProps,
  packagesToRemove: SubscribedPackageInfo[],
  shouldAddTax,
) => {
  if (!shouldAddTax) return ePackages;

  const hasPositivePrice = (pkg: EligiblePackageInfo) => {
    const {
      variant: { actualPrice },
    } = pkg;

    return actualPrice > 0.0;
  };
  const taxedPackages = ePackages.filter(
    (ePkg) => hasPositivePrice(ePkg) && !containsEligiblePackage(packagesToRemove, ePkg),
  );
  const fetchAllTaxes = taxedPackages.map((pkg) => fetchTax(pkg, props, packagesToRemove));
  const taxesResponses = await Promise.all(fetchAllTaxes);

  for (let i = 0; i < taxedPackages.length; i++) {
    const {
      variant: { id: vId },
    } = taxedPackages[i];

    const response = taxesResponses[i];
    const found = response.subscribedPackages?.find((sPkg) => sPkg.packageVariantId === vId);
    taxedPackages[i].variant.taxTotal = found?.taxTotal || 0.0;
  }
  return ePackages;
};

const fetchTax = (pkg: EligiblePackageInfo, props: SubscriptionProps, packagesToRemove: SubscribedPackageInfo[]) => {
  return previewOrders(props, Array.of(pkg), packagesToRemove);
};

export const applyPromoCodeOnPackages = async (
  props: SubscriptionProps,
  previewOrderRequestInfo: PreviewOrderRequestInfo,
) => {
  const { packagesToAdd, packagesToRemove, discountInfo } = previewOrderRequestInfo;

  const previewPromoCodePackages = packagesToAdd.map((eligiblePkg) =>
    previewOrders(props, [eligiblePkg], packagesToRemove, null, discountInfo as DiscountInfo),
  );
  const result = await Promise.allSettled(previewPromoCodePackages);
  const previewPromoCodeResponses = result
    .filter(({ status }) => status === 'fulfilled')
    .map((resp: PromiseFulfilledResult<PreviewOrderResponse>) => resp.value);

  return buildPackagesWithDiscount(packagesToAdd, previewPromoCodeResponses);
};

export const previewOrders = (
  props: SubscriptionProps,
  selectedPackages: PackageSubscription[],
  subscribedPackages: SubscribedPackageInfo[],
  paymentMethodId?: string,
  discountInfo?: DiscountInfo,
  packageToRemoveBuilder: PackageToRemoveBuilderType = packagesToRemove,
) => {
  const request: OrderRequest = buildOrderRequest(
    props,
    selectedPackages,
    subscribedPackages,
    true,
    null,
    packageToRemoveBuilder,
    paymentMethodId,
    discountInfo,
  );
  return previewOrder(request).then((res) => res.data);
};

export const requestToyotaOrderPreview = (
  props: SubscriptionProps,
  packageIds: string[],
  subscribedPackages: SubscribedPackage[],
) => {
  const { userDetails, vehicleDetails } = props;
  const request: OrderRequest = {
    ...options(props),
    data: {
      userId: userDetails?.userId,
      vin: vehicleDetails?.vin,
      packagesToAdd: formatPackages(packageIds),
      packagesToRemove: formatPackagesToRemove(subscribedPackages),
    },
  };
  return previewOrder(request).then((res) => res.data);
};

export const createOrders = (
  props: SubscriptionProps,
  selectedPackages: PackageSubscription[],
  subscribedPackages: SubscribedPackageInfo[],
  packageToRemoveBuilder: PackageToRemoveBuilderType,
  orderSummaryInfo: OrderSummaryInfo,
  paymentInfo: PaymentInfo,
  shouldIncludeTax: boolean,
  transactionId?: string,
  clientIP?: string,
  discountInfo?: DiscountInfo,
  skipAgreementCreation = false,
  cancelReason?: string,
) => {
  const tranSource = props.config.getOemValue(CONFIG_KEYS.TRANS_SOURCE);
  const transactionInfo = buildOrderTransactionInfo(tranSource, transactionId, clientIP);

  const request: OrderRequest = buildOrderRequest(
    props,
    selectedPackages,
    subscribedPackages,
    false,
    transactionInfo,
    packageToRemoveBuilder,
    paymentInfo?.paymentMethodId,
    discountInfo,
    cancelReason,
  );
  return createUserOrders(
    request,
    orderSummaryInfo,
    paymentInfo,
    props,
    true,
    shouldIncludeTax,
    skipAgreementCreation,
  ).then((res) => res.data);
};

export const cancelSubscriptions = async (
  props: SubscriptionProps,
  packagesToRemove: SubscribedPackage[],
  isCancelAll: boolean,
  shouldIncludeTax: boolean,
) => {
  const subscriptionsToRemove = buildSubscribedPackages(packagesToRemove);
  let orderSummaryInfo = null;
  let paymentInfo: PaymentInfo = null;

  if (!isCancelAll) {
    try {
      paymentInfo = await getDefaultPayment(props);
      orderSummaryInfo = await previewOrders(
        props,
        null,
        subscriptionsToRemove,
        paymentInfo?.paymentMethodId,
        null,
        packagesToCancel,
      );
    } catch (err) {
      console.error(
        `An error occurred while fetching paymentInfo or orderSummary to update agreement. ErrorMessage: ${
          err.data?.message ?? err
        }`,
      );
    }
  }
  return cancelPackages(
    props,
    subscriptionsToRemove,
    orderSummaryInfo ? buildOrderSummary(orderSummaryInfo) : null,
    paymentInfo,
    isCancelAll,
    shouldIncludeTax,
  );
};

const cancelPackages = async (
  props: SubscriptionProps,
  packagesToRemove: SubscribedPackageInfo[],
  orderSummaryInfo: OrderSummaryInfo,
  paymentInfo: PaymentInfo,
  isCancelAll: boolean,
  shouldIncludeTax: boolean,
) => {
  const {
    userDetails: { userId },
    vehicleDetails: { vehicleId },
  } = props;
  const clientIP = await getClientIp();
  const tranSource = props.config.getOemValue(CONFIG_KEYS.TRANS_SOURCE);
  const transactionInfo = buildOrderTransactionInfo(tranSource, '', clientIP);

  const request: OrderRequest = {
    ...options(props),
    data: {
      userId,
      vehicleId,
      transactionInfo,
      declineDefaultPackage: false,
      packagesToRemove: packagesToCancel(packagesToRemove, []),
    },
  };
  return createUserOrders(request, orderSummaryInfo, paymentInfo, props, !isCancelAll, shouldIncludeTax);
};

/**
 * Common service method that can be used to create order and agreement based on the input params.
 * It can used where ever we need one or both.
 * During orderCreation - Needs to create agreement as well
 * During Partial Cancellation - Need to update agreement with latest subscriptions
 */
const createUserOrders = async (
  request: OrderRequest,
  orderSummaryInfo: OrderSummaryInfo,
  paymentInfo: PaymentInfo,
  props: SubscriptionProps,
  isCreateAgreement: boolean,
  shouldIncludeTax: boolean,
  skipAgreementCreation = false,
) => {
  const createOrderResponse = await createOrder(request);

  if (!isCreateAgreement || createOrderResponse.status !== HttpStatus.ACCEPTED || skipAgreementCreation) {
    return createOrderResponse;
  }
  try {
    const subscribedPackages = await getPackagesSubscribed(props);
    const packages = buildSubscribedPackages(subscribedPackages);

    const summaryTotals = mapSubscriptionsWithSummaryTotals(packages, orderSummaryInfo, props.tenantId);

    await createAgreementForUser(props, summaryTotals, paymentInfo, shouldIncludeTax);
    return createOrderResponse;
  } catch (err) {
    console.error(`An error occurred while creating agreement. ErrorMessage: ${err.data?.message ?? err}`);
  }
};

const buildOrderRequest = (
  props: SubscriptionProps,
  selectedPackages: PackageSubscription[],
  subscribedPackages: SubscribedPackageInfo[],
  isPreview: boolean,
  transactionInfo,
  packageToRemoveBuilder: PackageToRemoveBuilderType = packagesToRemove,
  paymentMethodId?: string,
  discountInfo?: DiscountInfo,
  cancelReason?: string,
) => {
  const {
    userDetails: { userId },
    vehicleDetails: { vehicleId },
    salesChannel,
    tenantId,
    location,
  } = props;

  return {
    ...options(props),
    data: {
      userId,
      vehicleId,
      salesChannel: getSalesChannel(salesChannel, tenantId, location),
      retention: true,
      preview: isPreview,
      paymentMethodId,
      promoCodes: discountInfo?.promoCode ? [discountInfo.promoCode] : null,
      transactionInfo,
      packagesToAdd: packagesToAdd(selectedPackages),
      packagesToRemove: packageToRemoveBuilder(subscribedPackages, selectedPackages, cancelReason),
    },
  } as OrderRequest;
};

const formatPackages = (offerIds: string[]) =>
  offerIds.map((offerId, index) => ({
    autoRenew: false,
    packageVariantId: offerId,
    sequence: index,
  }));

const formatPackagesToRemove = (subscribedPackages: SubscribedPackage[]) => {
  return subscribedPackages.reduce((packages, sPackage) => {
    if (sPackage.packageType !== PackageType.Regular) {
      packages.push({
        cancelEffectiveDate: formatToCpsTZ(),
        cancelReason: CancelReason.Cancelled_default,
        cancellationPolicy: CancellationPolicy.Effective_date,
        subscriptionPackageId: sPackage.subscriptionPackageId,
      });
    }
    return packages;
  }, []);
};

const getSalesChannel = (salesChannel: SalesChannel, tenantId: TenantIds, location?: FlowLocation) => {
  return (tenantId === TenantIds.Honda || tenantId === TenantIds.Acura) && isMarketingFlow(location)
    ? SalesChannel.SubscriberPromotion
    : salesChannel;
};
